import React from "react";

const GreatDealsBanner = ()=> {
    return (
        <>
        <div className="greatDeals sm:py-28 py-10 sm:px-20 px-4">
            <div className="deal-content text-left sm:w-2/4 w-full">
                <h1 className="font-bold sm:text-3xl text-2xl text-blue-600 pb-6">Let us show you the world Discover our most popular destinations</h1>
                <div><a href="tel:+18339501349" className="py-3 px-3 bg-orange-500 text-white rounded-lg">Call us at +18339501349</a></div>
            </div>
        </div>
        </>
    )
}
export default GreatDealsBanner;