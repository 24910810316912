import React from "react";

const BestDestinationItem = ({image, title})=> {
    return (
        <>
        <div className="bestDeatination-item sm:w-1/3 w-full rounded-lg relative overflow-hidden">
                    <div className="destination-img relative">
                        <img src={image} alt="destinationImg"/>
                    </div>
                    <div className="destination-detail destn-content py-2 px-6 text-left absolute z-10 w-full">
                        <div className="pb-1">
                            <h3 className="text-white text-xl">{title}</h3>
                        </div>
                        <div className="pb-2 flex justify-start items-center gap-2">
                            <p className="flex gap-1">
                                <i className="fa-solid fa-star text-orange-400 text-sm"></i>
                                <i className="fa-solid fa-star text-orange-400 text-sm"></i>
                                <i className="fa-solid fa-star text-orange-400 text-sm"></i>
                                <i className="fa-regular fa-star text-orange-400 text-sm"></i>
                                <i className="fa-regular fa-star text-orange-400 text-sm"></i>
                            </p>
                            <span className="text-white text-sm">(2012 Reviews)</span>
                        </div>
                        <div className="flex justify-between items-center pt-1">
                            <div className="">
                                <p className="text-orange-400 text-sm">Price <span className="font-bold text-white text-xl">€190</span></p>
                                
                            </div>
                            <div>
                                <p className="text-white text-sm">150 Tours</p>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}
export default BestDestinationItem