import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from '../../assets/images/logo.png'

const Navbar = ()=> {
    const [isClassAdded, setIsClassAdded] = useState(false);
    const [scrolling, setScrolling] = useState(false);

    useEffect(() => {
    const handleScroll = () => {
        if (window.scrollY > 100) {
          setScrolling(true);
        } else {
          setScrolling(false);
        }
      };
      window.addEventListener('scroll', handleScroll);
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    const headerStyle = {
        backgroundColor: scrolling ? '#fff' : '#ffffff8c',
        transition: 'background-color 0.3s ease', 
    };

  const handleSidebar = () => {
    setIsClassAdded(!isClassAdded);
  };

  useEffect(() => {
    if (isClassAdded) {
      document.body.classList.add('sidebar_opened');
    } else {
      document.body.classList.remove('sidebar_opened');
    }
    // Cleanup function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove('sidebar_opened');
    };
  }, [isClassAdded]);
    useEffect(()=> {
        console.log('scrolling',scrolling)
    },[scrolling])
    return (
        <>
        <div className="navbar-container">
            <header style={headerStyle}>
                <div  className="container">
                    <div className="flex items-center justify-between sm:px-10 px-4">
                        <div className="logo w-1/5">
                            <NavLink to="/"><img src={logo} alt="logo" width={scrolling ? 90 : 150} /></NavLink>
                        </div>
                        <div className={scrolling? "w-4/5 flex sm:justify-start justify-end items-center md:flex-row-reverse gap-6" : "w-4/5 sm:block flex justify-end items-center gap-2"}>
                            <div className="call-now flex justify-end sm:mb-2">
                                <a href="tel:+18339501349" className="inline-flex justify-end gap-3 items-center">
                                    <div className="call-ring bg-blue-400 px-2 rounded-full text-center sm:flex justify-center items-center hidden">
                                      <i className="fa-solid fa-phone-volume text-2xl text-white"></i>
                                      <div className="ring-border"></div>
                                    </div>
                                    <div className="call-content text-left sm:block hidden">
                                        <p className="para-md3 text-sm">Call Us Toll-Free 24/7</p>
                                        <Link href="tel:+18339501349"><span className="para-lg3 font-bold text-lg text-gray-900">+18339501349</span></Link>
                                    </div>
                                    <div className="call-content text-left sm:hidden flex justify-center items-center gap-1">
                                      <i className="fa-solid fa-phone-volume text-xl text-sky-500"></i>
                                      <Link href="tel:+18339501349"><span className="para-lg3 font-bold text-lg text-gray-700 hover-underline">+18339501349</span></Link>
                                    </div>
                                </a>
                            </div>
                            <div className="main-nav flex justify-end gap-2">
                                <ul>
                                    <>
                                        <li><NavLink to='/' className="hover:text-sky-700">Home</NavLink></li>
                                        <li><NavLink to='/about' className="hover:text-sky-700">About us</NavLink></li>
                                        <li><NavLink to='/contact' className="hover:text-sky-700">Contact us</NavLink></li>
                                    </>
                                </ul>
                            {/* <button><Link  to='/login' className="py-2 px-4 bg-blue-500 text-white text-base rounded">Sign in</Link></button> */}
                            </div>
                            <div className="backdrop"></div>
                            <div className="mobile-menu-icon sm:hidden block" onClick={()=>handleSidebar(!isClassAdded)}>
                                {!isClassAdded ? <i className="fa-solid fa-bars text-xl bg-orange-600 rounded text-white px-2"></i> : <i className="fa-solid fa-xmark text-xl bg-white rounded text-blue-700 px-2"></i>}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
        </>
    )
}
export default Navbar;