import React, { useEffect, useState } from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import AboutSec from "../components/AboutSec";
import icon1 from '../assets/images/lounge-chair.png'
import icon2 from '../assets/images/travel.png'
import icon3 from '../assets/images/calm.png'
import PopularFlight from "../components/PopularFlight";
import GreatDealsBanner from "../components/GreatDealsBanner";
import BestDeals from "../components/BestDeals";
import TestimonialSec from "../components/TestimonialSec";
import CallingModal from "../components/CallingModal";

const Home =({tab, title})=> {
    const [isModal, setIsModal] = useState(false)
    const [showTravllerForm, setShowTravllerForm] = useState(false)
    const [totalTraveller, setTotalTraveller] = useState()
    const [formData, setFormData] =  useState({
        adults: 1,
        children: 0,
        infant: 0
    })
    const handleChange = (e)=> {
        setFormData({...formData, [e.target.name] : e.target.value})
    }
    const decreaseCounter = ()=> {
        if(formData.adults > 1){
        setFormData({...formData,
            adults: formData.adults - 1
        })
    }
    }
    const increaseCounter =()=> {
        if(formData.adults < 10){
        setFormData({...formData,
            adults: formData.adults + 1
        })
    }
    }
    const decreaseChildren =()=> {
        if(formData.children > 0){
        setFormData({...formData,
            children: formData.children - 1
        })
    }
    }
    const increaseChildren =()=> {
        if(formData.children < 10){
        setFormData({...formData,
            children: formData.children + 1
        })
    }
    }
    const decreaseInfant =()=> {
        if(formData.infant > 0){
        setFormData({...formData,
            infant: formData.infant - 1
        })
    }
    }
    const increaseInfant =()=> {
        if(formData.infant < 10){
        setFormData({...formData,
            infant: formData.infant + 1
        })
    }
    }
    useEffect(()=> {
        console.log('formData', formData)
        const totalTravellers = formData.adults + formData.children + formData.infant;
        setTotalTraveller(totalTravellers)
    },[formData])
  
    return (
        <>
        <div className="home-container">
        <div className="sm:h-screen h-auto banner-sec">
            <div className="main-content flex flex-col justify-end h-full text-left sm:mx-6 z-10 relative sm:px-14 px-4 gap-4 pb-10 sm:pt-2 pt-20">
                <h1 className="sm:text-5xl text-3xl text-white leading-snug font-bold sm:pb-10 pb-4">{title}</h1>
            <div className="flight-tab">
                <ul>
                    <li>
                        <a href="" className="bg-orange-600 px-6 py-4 rounded-lg text-white font-bold"><i className="fa-solid fa-jet-fighter-up rotate-45 mr-1"></i> {tab}</a>
                    </li>
                </ul>
            </div>
            <div className="tab-content search-field bg-blue-900 py-2 sm:px-4 px-6 rounded-lg">
                <div className="search-tab">
                <form>
                <div className="flex justify-start sm:items-center sm:flex-row flex-col sm:gap-8 gap-2 py-6">
                    <label class="radio-inline text-sky-100 flex gap-1">
                        <input type="radio" name="optradio" />One way
                    </label>
                    <label class="radio-inline text-sky-100 flex gap-1">
                        <input type="radio" name="optradio" />Round-trip
                    </label>
                    <label class="radio-inline text-sky-100 flex gap-1">
                        <input type="radio" name="optradio" />Multi-city
                    </label>
                </div>
              <ul className="flex justify-between items-center sm:gap-2 gap-4 mb-10 sm:flex-row flex-col">
                <li className="sm:w-2/12 w-full">
                    <input id='search_from' className="form-control py-2 px-2 rounded-lg w-full" type="text" placeholder="From" required autoComplete="off" />
                </li>
                <li className="sm:w-2/12 w-full">
                    <input id='search_to' className="form-control py-2 px-2 rounded-lg w-full" type="text" placeholder="To" required autoComplete="off" />
                </li>
                <li className="sm:w-auto w-full">
                    <input id='detarture_date' className="form-control py-2 px-2 rounded-lg w-full" type="date" placeholder="Departure" required autoComplete="off" />
                </li>
                <li className="sm:w-auto w-full">
                    <input id='return_date' className="form-control py-2 px-2 rounded-lg w-full" type="date" placeholder="Departure" required autoComplete="off" />
                </li>
                <li className="sm:w-36 w-full">
                    <select className="form-control py-2 px-2 rounded-lg w-full">
                        <option value='Economy'>Economy</option>
                        <option value='Economy'>Premium Economy</option>
                        <option value='Economy'>Business Class</option>
                        <option value='Economy'>Economy</option>
                    </select>
                </li>
                <li className="sm:w-auto w-full">
                    <div className="relative">
                    <div className="form-control py-2 px-2 rounded-lg bg-white text-sm cursor-pointer" onClick={()=> setShowTravllerForm(!showTravllerForm)}>
                        <i className="fa-solid fa-person"></i> {totalTraveller} Travellers <i className="fa-solid fa-chevron-down text-sm"></i>
                    </div>
                    {showTravllerForm && <div className="romTravlerForm bg-orange-400">
                        <div className="flex justify-between items-center gap-4 pb-2">
                            <p className="text-white font-bold">Adults, 16-64 Years</p>
                            <div className="counter flex justify-center">
                                <button type='button' className="bg-sky-600 text-white text-lg font-bold" onClick={decreaseCounter}>-</button>
                                <input className="w-14 bg-blue-200 px-2 text-center border-0 text-blue-900 font-bold" type="number" value={formData.adults} name='adults' id='adults' onChange={handleChange}/>
                                <button type='button' className="bg-sky-600 text-white text-base font-bold" onClick={increaseCounter}>+</button>
                            </div>
                        </div>
                        <div className="flex justify-between items-center gap-4 pb-2">
                            <p className="text-white font-bold">Children, 2-15 Years</p>
                            <div className="counter flex justify-center">
                                <button type='button' className="bg-sky-600 text-white text-lg font-bold" onClick={decreaseChildren}>-</button>
                                <input className="w-14 bg-blue-200 px-2 text-center border-0 text-blue-900 font-bold" type="number" value={formData.children} name='children' id='children' onChange={handleChange} />
                                <button type='button' className="bg-sky-600 text-white text-base font-bold" onClick={increaseChildren}>+</button>
                            </div>
                        </div>
                        <div className="flex justify-between items-center gap-4 pb-2">
                            <p className="text-white font-bold">Infant, Under 2</p>
                            <div className="counter flex justify-center">
                                <button type='button' className="bg-sky-600 text-white text-lg font-bold" onClick={decreaseInfant}>-</button>
                                <input className="w-14 bg-blue-200 px-2 text-center border-0 text-blue-900 font-bold" type="number" value={formData?.infant} name='infant' id='infant' onChange={handleChange}/>
                                <button type='button' className="bg-sky-600 text-white text-base font-bold" onClick={increaseInfant}>+</button>
                            </div>
                        </div>
                        <p className="text-gray-100 text-sm pt-4">*Only one infant per adult is allowed</p>
                        <div className="flex justify-end">
                            <button className="bg-white text-blue-600 rounded-lg py-2 px-4 text-sm font-bold" type="button" onClick={()=> setShowTravllerForm(false)}>OK</button>
                        </div>
                    </div>}
                    </div>
                </li>
                <li className="sm:w-auto w-full ml-0">
                    <button className="py-2 px-4 rounded-lg bg-orange-500 text-white" onClick={()=> setIsModal(true)}>Search</button>
                </li>
              </ul>
                </form>
                </div>
            </div>
            </div>
        </div>

        <section className="sm:px-20 px-4 sm:py-10 py-4">
            <div className="flex justify-between gap-6 sm:flex-row flex-col">
                <div className="w-full border-2 border-gray-200 rounded-lg py-4 px-4 hover:border-blue-500 delay-100 transition-all flex">
                    <div className="icon-box text-left">
                        <img src={icon1} alt="icon1"/>
                        <h5 className="pt-4 font-bold text-blue-900 text-2xl pb-2">You'll never roam alone</h5>
                        <p className="text-gray-700">Discount phone-exclusive airfares with partner airlines available online with "Book Now"
                        </p>
                    </div>
                   </div>
                   <div className="w-full border-2 border-gray-200 rounded-lg py-4 px-4 hover:border-blue-500 delay-100 transition-all">
                   <div className="icon-box text-left">
                        <img src={icon2} alt="icon1" />
                        <h5 className="pt-4 font-bold text-blue-900 text-2xl pb-2">A world of choice – anytime, anywhere</h5>
                        <p className="text-gray-700">More than 200 countries and regions & flights to over 5,000 cities
                        </p>
                    </div>
                   </div>
                   <div className="w-full border-2 border-gray-200 rounded-lg py-4 px-4 hover:border-blue-500 delay-100 transition-all">
                   <div className="icon-box text-left">
                        <img src={icon3} alt="icon1" />
                        <h5 className="pt-4 font-bold text-blue-900 text-2xl pb-2">Peace of mind, wherever you wander</h5>
                        <p className="text-gray-700">Travel worry-free knowing that we're here if you need us, 24 hours a day, agents are always here to help you
                        </p>
                    </div>
                   </div>
            </div>
        </section>
        <CallingModal isModal={isModal} setIsModal={setIsModal} />
        <PopularFlight />
        <TestimonialSec />
        <BestDeals />
        <GreatDealsBanner />
        </div>
        </>
    )
}

export default Home;