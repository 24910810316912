import './App.css';
import Navbar from './components/global/Navbar';
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from './pages/Home';
import Footer from './components/global/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from './Redux/store';
import About from './pages/About';
import Contact from './pages/Contact';
import Hotels from './pages/Hotels';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import TermConditions from './pages/TermConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CancelRefund from './pages/CancelRefund';

function App() {
 
  return (
    <I18nextProvider i18n={i18n}>
    <div className="App">
      <ToastContainer />
      <Provider store={store}>
       <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path='/'>
          <Route path="/" element={<Home tab='flights' title='Amazing Flight Waiting for you'/>} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/hotels" element={<Hotels />}/>
          <Route path="/termsconditions" element={<TermConditions />}/>
          <Route path="/privacypolicy" element={<PrivacyPolicy />}/>
          <Route path="/cencelpolicy" element={<CancelRefund />}/>
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
    </Provider>
    </div>
    </I18nextProvider>
  );
}

export default App;
