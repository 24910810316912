import React, { useState } from "react";
import DestinationItem from "./DestinationItem";
import BestDestinationItem from "./BestDestinationItem";
import destImg1 from '../assets/images/singapor4.jpg'
import destImg2 from '../assets/images/london.jpg'
import destImg3 from '../assets/images/dubai1.jpg'
import destImg4 from '../assets/images/francee.jpg'
import destImg5 from '../assets/images/italy_.jpg'
import destImg6 from '../assets/images/uk.jpg'
import CallingModal from "./CallingModal";

const BestDeals = ()=> {
    const [isModal, setIsModal] = useState(false)
    return (
        <>
        <div className="sm:px-20 px-4 sm:py-10 sm:py-4 py-2">
            <div className="sec-heading text-center sm:pb-4 pb-2">
                <h1 className="font-bold sm:text-3xl text-2xl text-orange-500 smL:pb-4 pb-2">Handpicked Deals For You</h1>
                <a href="#" className="text-sm text-blue-500" onClick={()=> setIsModal(true)}>View All <i className="fa-solid fa-arrow-right text-sm text-blue-500"></i></a>
            </div>
            <div className="destination-box flex justify-between sm:flex-row flex-col items-center gap-4 sm:py-4">
                <BestDestinationItem image={destImg1} title='Sigapore'/>
                <BestDestinationItem image={destImg2} title='London'/>
                <BestDestinationItem image={destImg3} title='Dubai'/>
            </div>
            <div className="destination-box flex justify-between sm:flex-row flex-col items-center gap-4 py-4">
                <BestDestinationItem image={destImg4} title='France'/>
                <BestDestinationItem image={destImg5} title='Italy'/>
                <BestDestinationItem image={destImg6} title='United kingdom'/>
            </div>
            <div></div>
            <CallingModal isModal={isModal} setIsModal={setIsModal} />
        </div>
        </>
    )
}
export default BestDeals;