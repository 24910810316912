import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../../assets/images/logo.png'
import callbackImg from '../../assets/images/callback-hover.png'
import CallingModal from "../CallingModal";

const Footer = ()=> {
    const [isModal, setIsModal] = useState(false)
    return (
        <>
         <div className="sm:px-20 px-4 bg-gray-50 footer relative">
            <CallingModal isModal={isModal} setIsModal={setIsModal} />
            <div className="footer-sec py-10 text-left">
                <div className="flex justify-between sm:flex-row flex-col gap-10">
                    <div className="sm:w-1/3 w-full">  
                        <div className="col-md-3">
                            <img src={logo} alt="logoImg" width={120}/>
                        </div>
                        <p className="text-gray-600 text-base pt-2">
                            The team of Goflyeasy LLC aims to make the experience of flight booking effortless to all our customers. We will select the most suitable flights for you, which are having affordable airline tickets.
                        </p>
                    </div>
                    {/* <div className="w-1/2">
                        <h4 className="text-blue-500">About</h4>
                        <p className="text-gray-600 text-base">
                        The team of Airfareify aims to make the experience of flight booking effortless to all our customers. We will select the most suitable flights for you, which are having affordable airline tickets. Our service will not only save your travel expenses but will let you relax and pack for the trip calmly without any trouble related to the tickets.
                        </p>
                    </div> */}
                    <div className="sm:w-1/4 w-2/4">
                        <h4 className="text-blue-500">Useful Links</h4>
                        <ul className="footer-links pt-4">
                            <li className="py-1"><Link to='/' className="text-gray-600 hover:text-blue-500 transition-all">Home</Link></li>
                            <li className="py-1"><Link to='/about' className="text-gray-600 hover:text-blue-500 transition-all">About us</Link></li>
                            <li className="py-1"><Link to='/contact' className="text-gray-600 hover:text-blue-500 transition-all">Contact us</Link></li>
                            <li className="py-1"><Link to='/hotels' className="text-gray-600 hover:text-blue-500 transition-all">Hotels</Link></li>
                            <li className="py-1"><Link to='/cencelpolicy' className="text-gray-600 hover:text-blue-500 transition-all">Cancellatio policy</Link></li>
                            <li className="py-1"><Link to='/termsconditions' className="text-gray-600 hover:text-blue-500 transition-all">Term and conditions</Link></li>
                            <li className="py-1"><Link to='/privacypolicy' className="text-gray-600 hover:text-blue-500 transition-all">Privacy policy</Link></li>
                        </ul>
                    </div>
                    <div className="sm:w-1/3 w-full">
                        <h4 className="text-blue-500">Contact us</h4>
                        <div className="footer-contact pt-4">
                            <a href="tel:+18339501349"><p className="text-gray-600 flex gap-2 items-center justify-start"><i className="fa-solid fa-phone text-orange-400 text-sm"></i> <span>+18339501349</span></p></a>
                            <p className="text-gray-600 flex gap-2 items-center justify-start"><i className="fa-solid fa-envelope text-orange-400 text-sm"></i> <span>reservations@goflyeasyllc.com</span></p>
                            <p className="text-gray-600 flex gap-2 justify-start pt-1"><i className="fa-solid fa-location-dot text-orange-400"></i> 
                                <span>6023 carnaby ln rosenberg <br /> tx 77471-4582</span>
                            </p>
                        </div>
                        <div className=" pt-4">
                    {/* <ul className="social-network flex gap-4">
                        <li className="bg-orange-400 hover:bg-orange-500 h-8 w-8 rounded-full flex justify-center items-center"><Link to='/'><i className="fa-brands fa-facebook-f text-white"></i></Link></li>
                        <li className="bg-orange-400 hover:bg-orange-500 h-8 w-8 rounded-full flex justify-center items-center"><Link to='/'><i className="fa-brands fa-x-twitter text-white"></i></Link></li>
                        <li className="bg-orange-400 hover:bg-orange-500 h-8 w-8 rounded-full flex justify-center items-center"><Link to='/'><i className="fa-brands fa-linkedin-in text-white"></i></Link></li>
                    </ul> */}
                </div>
                    </div>
                </div>
            </div>
            <div className="fixed bottom-0 right-0 py-6 px-10 z-40">
            <div className="call-ring bg-yellow-500 px-2 rounded-full text-center flex justify-center items-center cursor-pointer relative footer-call-ring" onClick={()=> setIsModal(true)}>
                <i className="fa-solid fa-phone-volume text-2xl text-white"></i>
                <div className="ring-border"></div>
            <div class="voucherDiv absolute">
                <img src={callbackImg} alt="callImg" />
                <div className="bg-blue-500 text-white py-2 px-2 text-sm">Call Us to Get <span className="font-bold py-1 px-1 text-white bg-orange-500 rounded-lg">$30 EXTRA</span> lower then online
            </div>
            </div>
            </div>
            </div>
            </div>
            <div className="px-10 py-3">
                <div className="text-center">
                <p className="text-gray-500 font-light text-sm text-center"><b>© reservadepasajesaéreosm.live</b> - All Right Reserved</p>
                </div>
            </div>
        </>
    )
}
export default Footer