import React, { useEffect, useState } from "react";
import 'swiper/css';
import 'swiper/css/navigation';
import Home from "./Home";

const Hotels =()=> {
  
    return (
        <>
        <Home tab='Hotels' title='Find & Book Hotels at Best Deal'/>
        </>
    )
}

export default Hotels;