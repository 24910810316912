import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import BaseUrl from '../Api/BaseUrl';

export const fetchOfferList = createAsyncThunk(
    'admin/offerList',
    async (adminId) => {
        const response = await fetch(`${BaseUrl}admin/offerList?adminId=${adminId}`);
        const responseData = await response.json();
        return responseData
      }
)
export const fetchOfferDetail = createAsyncThunk(
    'admin/offerdetails',
    async ({adminId, offerId}) => {
        const response = await fetch(`${BaseUrl}admin/offerdetails?adminId=${adminId}&offerId=${offerId}`);
        const responseData = await response.json();
        return responseData
      }
)
export const addOffer = createAsyncThunk(
    'admin/createOffer',
    async ({formData,trackingUrl}) => {
        const response = await fetch(`${BaseUrl}admin/createOffer?trackingUrl=${trackingUrl}`, {
            method: 'POST',
            headers: {
                'accept': 'application/json'
            },
            body: new URLSearchParams(formData)
        });
        const responseData = await response.json();
        return responseData
      }
)

export const OfferSlice = createSlice({
    name: 'offer    ',
    initialState: {
        list: [],
        details: null,
        loading : false,
        error : null
      },

      reducers: {
    
      },

      extraReducers: (builder) => {
        // fetch builder
       builder.addCase(fetchOfferList.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(fetchOfferList.fulfilled, (state, action) => {
         state.list =  action.payload.responseResult;
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(fetchOfferList.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
        // Add builder
       builder.addCase(addOffer.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(addOffer.fulfilled, (state, action) => {
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(addOffer.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
        // fetch Detail builder
       builder.addCase(fetchOfferDetail.pending, (state, action) => {
         state.loading =  true;
       });
       builder.addCase(fetchOfferDetail.fulfilled, (state, action) => {
        state.details =  action.payload.responseResult;
         state.loading =  false;
         state.error =  false;
       });
       builder.addCase(fetchOfferDetail.rejected, (state, action) => {
         state.error =  true;
         state.loading =  false;
       });
    }
})

export default OfferSlice.reducer