import React from "react";
import modalImg from '../assets/images/callback-staff.jpg'

const CallingModal = ({isModal, setIsModal})=> {
    return (
        <>
        {isModal && <div class="postback-modal overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full flex">
    <div class="relative p-4 w-full max-w-2xl max-h-full">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="bg-blue-500 rounded-lg">
                <div class="flex items-center justify-end p-4 md:p-5 rounded-t">
                    <button type="button" class="text-blue-900 bg-sky-100 hover:bg-orange-500 hover:text-white rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={()=> setIsModal(false)}>
                        <i className="fa-solid fa-xmark text-xl"></i>
                    </button>
                </div>
                <div className="sm:w-1/3 w-4/5 mx-auto callImg flex justify-center items-center">
                    <img src={modalImg} alt="Callimg"/>
                    <p className="absolute bg-green-600 py-1 px-2 text-white rounded-3xl text-sm border-2 border-green-300 w-20">Available Now</p>
                </div>
            </div>
            <div className="p-4 md:p-5 text-center">
                <h2 className="text-xl leading-relaxed text-blue-900 dark:text-gray-400 font-bold">
                    Want airfares up to $30 lower than online?
                </h2>
                <p className="text-blue-900 mt-0 pb-4">We have thousands of lower unpublished deals that we cannot display online as those are restricted by airlines for offline sale only.</p>
                <a href="tel:+18339501349" className="bg-orange-400 py-2 px-2 rounded-3xl flex justify-center items-center gap-2 sm:w-1/3 w-2/3 mx-auto callAnimation">
                    <i className="fa-solid fa-phone-volume text-lg text-white"></i> 
                    <span className="para-lg3 font-bold text-lg text-white relative">+18339501349</span>
                </a>
                <p className="font-bold text-xl text-orange-500 pt-2 text-center">Call Us to Get Special Supar Deals</p>
            </div>
            {/* <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button type="button" class="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={()=> setIsModal(false)}>Close</button>
            </div> */}
        </div>
    </div>
</div>}
        </>
    )
}
export default CallingModal;