import React, { useState } from "react";
import destinationImg from '../assets/images/destination1.jpg'
import CallingModal from "./CallingModal";

const DestinationItem = ({image, destnfrom, destnto,price})=> {
    const [isModal, setIsModal] = useState(false)
    return (
        <>
        <div className="deatination-item sm:w-1/3 w-full rounded-lg">
                    <img src={image} alt="destinationImg"/>
                    <div className="destination-detail py-4 px-4 text-left">
                        <div className="flex justify-between items-center pb-1">
                            <span className="font-bold">{destnfrom}</span>
                            <i className="fa-solid fa-right-left text-orange-500 text-xl"></i>
                            <span className="font-bold">{destnto} </span>
                        </div>
                        <hr className="text-slate-50" />
                        <div className="flex justify-between items-center pt-1">
                            <div>
                                <p className="text-gray-700 text-sm">From</p>
                                <span className="font-bold text-blue-500 text-2xl">{price}$</span>
                            </div>
                            <div>
                                <p className="text-orange-400 hover:underline cursor-pointer text-sm" onClick={()=> setIsModal(true)}>See details <i className="fa-solid fa-chevron-right"></i></p>
                            </div>
                        </div>
                    </div>
                </div>
                <CallingModal isModal={isModal} setIsModal={setIsModal} />
        </>
    )
}
export default DestinationItem