import React, { useState } from "react";
import DestinationItem from "./DestinationItem";
import destination1 from '../assets/images/Singapore.jpg'
import destination2 from '../assets/images/dubai.jpg'
import destination3 from '../assets/images/italy.jpg'
import destination4 from '../assets/images/france.jpg'
import destination5 from '../assets/images/unitedState.jpg'
import destination6 from '../assets/images/canada.jpg'
import CallingModal from "./CallingModal";

const PopularFlight = ()=> {
    const [isModal, setIsModal] = useState(false)
    return (
        <>
        <div className="sm:px-20 px-4 sm:py-10 sm:py-4 py-2">
            <div className="sec-heading text-center sm:pb-4 pb-2"> 
                <h1 className="font-bold sm:text-3xl text-2xl text-orange-500 pb-4">Most Popular Round-trip Flight Destinations</h1>
                <a href="#" className="text-sm text-blue-500" onClick={()=> setIsModal(true)}>View All <i className="fa-solid fa-arrow-right text-sm text-blue-500"></i></a>
            </div>
            <div className="destination-box flex justify-between sm:flex-row flex-col items-center gap-4 sm:py-4">
                <DestinationItem image={destination1} destnfrom='Singapore' destnto='Dubai' price='500'/>
                <DestinationItem image={destination2} destnfrom='Dubai' destnto='London' price='600' />
                <DestinationItem image={destination3} destnfrom='Italy' destnto='Singapore' price='550' />
            </div>
            <div className="destination-box flex justify-between sm:flex-row flex-col items-center gap-4 py-4">
                <DestinationItem image={destination4} destnfrom='France' destnto='London' price='650' />
                <DestinationItem image={destination5} destnfrom='United State' destnto='Dubai' price='700' />
                <DestinationItem image={destination6} destnfrom='Canada' destnto='France' price='500' />
            </div>
            <div></div>
            <CallingModal isModal={isModal} setIsModal={setIsModal} />
        </div>
        </>
    )
}
export default PopularFlight;