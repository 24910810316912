import React from "react";

const CancelRefund = ()=> {
    return (
        <>
          <div className="about-sec">
            <div className="about-banner sm:px-20 px-4 sm:py-20 py-10 flex justify-center items-center">
                <h2 className="font-bold sm:text-5xl text-4xl text-center text-white absolute bottom-0 sm:pb-14 pb-4 z-10">POLÍTICA DE CANCELACIÓN Y REEMBOLSO</h2>
            </div>
            <div className="termsConditions sm:px-20 px-4 sm:py-10 py-6 text-left">
                <p className='pb-2'>Para tarifas reembolsables, se puede cobrar una tarifa de procesamiento por parte del Servicio de atención al cliente de Flightiers por todas las cancelaciones permitidas.</p>
                <p className="pb-2">
                La mayoría de las tarifas de servicios aéreos y boletos aéreos NO SON REEMBOLSABLES. El seguro de protección de vacaciones se puede reembolsar dentro de los 10 días posteriores a la compra en caso de que el viaje no haya comenzado y el cliente haya llamado al servicio de atención al cliente de la aerolínea para cancelar. Todas las cancelaciones deben realizarse únicamente por teléfono. Las solicitudes de reembolso se aceptarán únicamente después de que se hayan cumplido las siguientes condiciones:</p>
                <p className='pb-2'>
                El viajero ha solicitado cancelación y reembolso con nosotros y las reglas de la aerolínea permiten lo mismo.
                El viajero no debe “no presentarse”. La mayoría de las reservas sin presentación no son elegibles para el procesamiento de reembolsos por parte de los proveedores.
                Nosotros garantizamos las exenciones de las aerolíneas para procesar la solicitud de cancelación y reembolso.
                Para los reembolsos de billetes de avión, es difícil proporcionar la fecha exacta o el tiempo que puede tardar el proceso de reembolso. Todas las solicitudes de reembolso se procesan en secuencia. Una vez que se envía una notificación al agente de servicio al cliente, le enviaremos una notificación por correo electrónico para confirmar dicha solicitud. Sin embargo, esta notificación es solo un reconocimiento de su solicitud y no lo califica automáticamente para un reembolso. Al recibir la solicitud, trabajamos con las aerolíneas interesadas para llegar a un monto exento según las reglas de las aerolíneas y luego le notificamos la decisión de las aerolíneas. Tenga en cuenta que dependemos de las aerolíneas para recibir reembolsos. Después de la aprobación del reembolso por parte de las aerolíneas, es posible que sea necesario un tiempo adicional para que el mismo se refleje en el extracto de su tarjeta de crédito. Generalmente, todas las aerolíneas cobran una multa por los reembolsos. Todo el proceso, desde la recepción de su solicitud de reembolso hasta el reflejo de la misma en el extracto de su tarjeta de crédito, puede tardar entre 60 y 90 días. Además de las sanciones por reembolso impuestas por la aerolínea, Flightiers cobrará una tarifa de $50 por boleto para procesar este reembolso. Estas tarifas se cobrarán únicamente si la aerolínea ha autorizado el reembolso o se ha recibido una exención y cuando dichos reembolsos estén permitidos por las reglas de la aerolínea. En caso de que la aerolínea no procese su reembolso, le reembolsaremos las tarifas de procesamiento que le cobramos.
                </p>
                <h2 className="font-bold text-3xl text-gray-700">CANCELAR Y CAMBIAR</h2>
                <p className='pb-2'>
                La mayoría de los billetes de avión no son reembolsables ni transferibles. En los casos en que la aerolínea permita cancelaciones, un crédito puede ser válido para la compra de boletos en el futuro con viajes en vuelos de la misma aerolínea. En tales casos se sumará la diferencia de tarifa (si la hubiera) y la penalidad aplicable. Las limitaciones de tiempo y la fecha de vencimiento de dicho crédito deben discutirse de antemano con el agente de servicio al cliente. Todas las reservas que permiten cancelación deben cancelarse antes de la hora de salida programada del vuelo llamando a nuestro representante de servicio al cliente. No garantizamos ninguna cancelación. En el momento de la cancelación de la reserva, se nos deberá pagar una tarifa de cancelación de $50 por boleto. Esta tarifa la retenemos nosotros. El crédito disponible debe usarse dentro de un período de tiempo y fecha determinados o perderá el monto del crédito. Al realizar una nueva reserva, deberá pagar la diferencia de tarifa aplicable, las multas de la aerolínea y las tarifas de cambio de Travel Inc. Todos estos cambios se rigen por las normas y reglamentos de las aerolíneas. Flightiers no ofrece representaciones ni garantías sobre tarifas o cambios. Lea nuestra política de reembolsos si cancela una reserva o solicita un reembolso. Para cambiar las fechas de vuelo o la ruta, lea más sobre nuestra Política de cambios.
                </p>
            </div>
            </div>
        </>
    )
}
export default CancelRefund;