import React from "react";
const TermConditions = ()=> {
    return (
        <>
        <div className="about-sec">
            <div className="about-banner sm:px-20 px-4 sm:py-20 py-10 flex justify-center items-center">
                <h2 className="font-bold sm:text-5xl text-4xl text-center text-white absolute bottom-0 sm:pb-14 pb-4 z-10">Terms & Conditions</h2>
            </div>
            <div className="termsConditions sm:px-20 px-4 sm:py-10 py-6 text-left">
                <p className='pb-2'>El plazo de reembolso para todas las cancelaciones realizadas dentro de las 24 horas posteriores a la reserva del vuelo será de 48 a 72 horas. En caso de una solicitud de reembolso urgente, debe comunicarse con su banco y obtener el código de autenticación.
                En caso de que las cancelaciones se realicen directamente con la aerolínea, se debe informar a reservadepasajesareosm-r8b.live y le proporcionaremos el crédito por un monto igual a la tarifa base, después de la tarifa de servicio e impuestos.
                Las tarifas no están garantizadas hasta que se emita el billete y pueden cambiar sin previo aviso.</p>
                <p className="pb-2">
                Si la tarifa reservada ya no está disponible, le proporcionaremos opciones alternativas. Tienes derecho a cancelar la reserva sin coste alguno o seleccionar la nueva opción con la tarifa actualmente disponible.
                Una vez que se haya cobrado el pago, los boletos se le enviarán por correo electrónico dentro de las 4 horas posteriores a la realización del pago completo.
                Si su tarjeta de crédito es rechazada, se informará al cliente al mismo tiempo por correo electrónico o teléfono. En tales casos, es responsabilidad del cliente hablar con el banco y asegurarse de que haya suficiente saldo disponible en la tarjeta para la emisión de billetes.
                Es posible que se apliquen cargos adicionales por el uso de varias tarjetas.<br />
                No se permiten cambios de nombre. Si hubiera algún error en la reserva/billete, el cliente deberá comunicarlo el mismo día de la emisión de los billetes.</p>
                <p className='pb-2'>
                Los cambios y cancelaciones están sujetos a penalizaciones por parte de la aerolínea, mientras que algunos boletos, aunque no son reembolsables, pueden ser reutilizables en una fecha posterior según las reglas tarifarias de la aerolínea. Los boletos parcialmente usados ​​y las NO presentaciones no son reembolsables en absoluto. En caso de NO presentarse, la aerolínea perderá el importe total del billete.
                El plazo de reembolso puede variar de una aerolínea a otra. Por lo general, recomendamos a nuestros clientes un tiempo de 10 a 12 semanas para que el monto del reembolso se refleje en su cuenta. El reembolso solo se puede procesar en la forma de pago original.
                Los billetes de avión suelen tener una validez de un año a partir de la fecha de emisión. Cualquier billete tendrá un límite de tiempo en el que deberá completarse el viaje, en algunos casos la validez podría no ser posible debido a restricciones tarifarias. Para obtener más información, comuníquese con las respectivas aerolíneas.
                Por “Nacional” nos referimos a cualquier viaje dentro de los 50 Estados Unidos y por “Internacional” todos viajamos excepto los viajes nacionales definidos anteriormente. El tiempo de check-in recomendado para viajes nacionales es 2 horas antes de la salida y para viajes internacionales es 3 horas antes de la salida. Vuelva a confirmar su asiento y otras solicitudes especiales, como millas de viajero frecuente, con las aerolíneas directamente al menos 3 días antes de la salida.
                Bebé se define como un viaje que tiene menos de 24 meses de edad en el momento del viaje; de ​​lo contrario, se aplican tarifas para niños.<br />
                La política para viajes sin acompañante varía de una aerolínea a otra. Confirme amablemente antes de hacer una nueva reserva si viaja un menor no acompañado llamando a nuestro número gratuito +183-395-01349.
                </p>
                <p className='pb-2'>
                Asegúrese de presentarse en el mostrador de la aerolínea correcta si el vuelo es operado por un socio de código compartido.
                Es su responsabilidad asegurarse de que todos los detalles de sus documentos de viaje sean correctos y comunicarnos cualquier error o discrepancia de inmediato. Obtenga una VISA antes de su fecha de salida. Tourexo no gestiona Visas. Asegúrese de obtener una VISA para el país al que viaja y los países en tránsito (si corresponde). Para obtener más información sobre Visa, póngase en contacto con la aerolínea o el consulado del país respectivo.<br />
                Tourexo no asume ninguna responsabilidad si una aerolínea cancela, reprograma o retrasa un vuelo por cualquier motivo. Para todas las reservas realizadas a través de nosotros, intentaremos ayudarlo a realizar nuevos arreglos, siempre que aún no haya registrado con su aerolínea para su primer segmento de vuelo. Después del check-in, se supone que usted trabajará directamente con la aerolínea para llegar a su destino o hacer arreglos alternativos, incluidas modificaciones en los servicios de regreso. A menudo se aplican tarifas de las aerolíneas por modificaciones de los horarios aéreos que no están relacionadas con cancelaciones, reprogramaciones o retrasos de vuelos impuestos por las aerolíneas. Estas tarifas se pagarán a la aerolínea directamente en el momento de la solicitud.
                </p>
            </div>
            </div>
        </>
    )
}
export default TermConditions;