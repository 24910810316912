import React from "react";
const PrivacyPolicy = ()=> {
    return (
        <>
        <div className="about-sec">
            <div className="about-banner sm:px-20 px-4 sm:py-20 py-10 flex justify-center items-center">
                <h2 className="font-bold sm:text-5xl text-4xl text-center text-white absolute bottom-0 sm:pb-14 pb-4 z-10">política de privacidad</h2>
            </div>
            <div className="sm:px-20 px-4 sm:py-10 py-6 text-left">
                <p className='pb-2'>Flightiers.com ha creado esta declaración de privacidad para demostrar su compromiso con la privacidad de sus clientes. A continuación se describen nuestras prácticas de recopilación y difusión de información para Flightiers.com, así como boletines informativos por correo electrónico. Es importante que comprenda qué información recopilamos sobre usted durante su visita y qué hacemos con esa información. Esta Política de Privacidad solo se aplica a la información recopilada en Flightiers.com y no a la información recopilada por Flightiers.com a través de cualquier otro medio. Su visita a Flightiers.com está sujeta a esta Política de privacidad y a nuestros Términos de uso.</p>
                    <h2 className="text-3xl font-bold text-gray-700">1. Recopilación y uso de información personal</h2>
                <p className="pb-2">
                No recopilamos ningún tipo de información personal sobre usted, excepto cuando la proporciona a Flightiers.com. Por ejemplo, si nos envía una pregunta o se suscribe a nuestros boletines semanales en Flightiers.com, es posible que se le solicite que proporcione cierta información, como su nombre, dirección de correo electrónico, dirección postal, etc. Cuando envía su información personal a Flightiers.com, usted da su consentimiento para la recopilación, uso y divulgación de su información personal según lo establecido en esta Política de Privacidad. No venderemos ni alquilaremos su información personal a ningún tercero sin su consentimiento, solo usaremos la información que nos proporcione con el fin de responder su consulta. Por ejemplo, si opta por recibir nuestra alerta por correo electrónico, podemos utilizar su dirección de correo electrónico para enviarle información sobre las últimas ofertas y descuentos.</p>
                    <h2 className="text-3xl font-bold text-gray-700">2. Dirección de correo electrónico</h2>
                <p className='pb-2'>
                No queremos que reciba correos electrónicos no deseados de Flightiers.com. Hacemos todo lo posible para que le resulte más fácil darse de baja de cualquier servicio que haya solicitado recibir. Si se suscribe a nuestros boletines informativos por correo electrónico, no vendemos, intercambiamos ni damos su dirección de correo electrónico a ningún tipo de tercero. Las direcciones de correo electrónico se recopilan a través del sitio web Flightiers.com o de socios de registro conjunto y listas de marketing y promociones de terceros. Los usuarios deben optar por recibir el boletín informativo de Flightiers.com y también se envía un correo electrónico de verificación al destinatario. Flightiers.com valora la seguridad de su información personal. Seguimos los estándares de la industria para proteger la información personal que se nos proporciona, tanto durante el registro como una vez que la recibimos. No existe ningún método de transmisión a través de Internet o método de almacenamiento electrónico que sea 100 % seguro, pero en nuestro caso utilizamos servidores altamente seguros para proteger su información personal, pero no podemos garantizar su seguridad absoluta.
                </p>
                    <h2 className="text-3xl font-bold text-gray-700">3. Acceso a información de identificación personal</h2>
                <p className='pb-2'>
                Si desea cambiar o actualizar la dirección de correo electrónico, o si no desea nuestro servicio, puede actualizar, eliminar o desactivar sus datos comunicándose con nuestro servicio de atención al cliente o visitando la página Contáctenos.
                </p>
                    <h2 className="text-3xl font-bold text-gray-700">4. Galletas</h2>
                <p className="pb-2">Flightiers.com uses cookies to improve user experience such as improving navigation and relevancy of product and information on the website. Your web browser should allow you to choose whether or not to allow cookies - you can still use our website if you have them disabled. For more information about cookies and how it works, visit cookiecentral.com</p>
                    <h2 className="text-3xl font-bold text-gray-700">5. Sitios de terceros y herramientas de reserva</h2>
                <p className='pb-2'>Flightiers.com no vende ningún tipo de producto o servicio de viajes, pero lo dirige a sitios web de terceros que sí lo hacen. Estos sitios pueden recopilar información personal sobre usted, como su nombre, dirección, detalles de su tarjeta de crédito u otros detalles que pueden usarse como métodos de pago. Estos sitios web pueden tener sus propias políticas de privacidad para utilizar su información personal.</p>
                    <h2 className="text-3xl font-bold text-gray-700">6. Third Party Advertisers</h2>
                <p className='pb-2'>Los banners publicitarios, gráficos, videos y otras formas de publicidad que aparecen en este sitio web a veces son entregados a usted, en nuestro nombre, por un tercero. Para poder publicar anuncios relevantes en este sitio web, el tercero puede colocar o reconocer una cookie única en su navegador.</p>
                    <h2 className="text-3xl font-bold text-gray-700">7. Transferencias comerciales</h2>
                <p className='pb-2'>A medida que continuamos desarrollando nuestro negocio ampliamente, es posible que vendamos ciertas entidades o activos. En este tipo de transacciones, la información del usuario, incluida la información personal, generalmente es uno de los activos comerciales transferidos y, al enviar su información personal en Flightiers.com, acepta que en este tipo de circunstancias sus datos pueden transferirse a dichas partes.</p>

            </div>
            </div>
        </>
    )
}
export default PrivacyPolicy;