import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translations

const resources = {
    en: {
      translation: {
            "Hello World!": "Hola, mundo!"
      }
    },
  };

  i18n
    .use(initReactI18next)
    .init({
    resources,
    lng: 'es', // Set default language to Spanish
    fallbackLng: 'es', // Fallback to Spanish if translation is missing
    debug: true,
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;