import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

const TestimonialSec = ()=> {
    return (
        <>
        <div className="testimonial-sec sm:px-20 px-4 sm:py-20 py-10 my-4">
            <h2 className="text-white text-center font-bold sm:text-3xl text-2xl pb-10">What Our Customers Say</h2>
            <div className="w-full mx-auto">
            <div className="pb-4"><i className="fa-solid fa-circle-user text-orange-500 text-5xl border-8 border-gray-300 rounded-full"></i></div>
            <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
            <div className="testi-content sm:w-3/4 w-11/12 mx-auto">
                <p className="text-sky-100 pb-2">I recently bought flight tickets from to Airfareify and had a very smooth and hassle-free free experience with them. With all the flight rates listed in one place, I could easily compare the timings and rates of different lights and make a quick decision after seeing the best travel fares.</p>
                <span className="font-bold text-orange-500">- Barbara Calvert</span>
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="testi-content sm:w-3/4 w-11/12 mx-auto">
            <p className="text-sky-100 pb-2">I travel very often for work and always found booking tickets quite cumbersome earlier. But with Airfareify it seems like a cakewalk. The Airfareify agent provided me great deals which helped me save on my travel budget. Above all, the process was quick and hassle-free and they offer good assistance over a call.</p>
            <span className="font-bold text-orange-500">- Kenneth Salomon</span>
            </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="testi-content sm:w-3/4 w-11/12 mx-auto">
                <p className="text-sky-100 pb-2"> The Airfareify agent provided me great deals which helped me save on my travel budget. Above all, the process was quick and hassle-free and they offer good assistance over a call.</p>
                <span className="font-bold text-orange-500">- Kenneth Salomon</span>
            </div>
            </SwiperSlide>
        </Swiper>
        </div>

        </div>
        </>
    )
}

export default TestimonialSec;