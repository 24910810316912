import React from "react";

const Contact = ()=> {
    return (
        <>
        <div className="about-banner sm:px-20 px-4 sm:py-20 py-10 flex justify-center items-center">
                <h2 className="font-bold sm:text-5xl text-4xl text-center text-white absolute bottom-0 sm:pb-14 pb-4 z-10">Contact Us</h2>
            </div>
         <div className="py-10 sm:px-10 px-4 container mx-auto">
        <div className="sec-heading pb-4">
            <h2 className="font-bold sm:text-4xl text-3xl text-neutral-700 pb-2">Get in touch</h2>
            <small className="text-xl text-neutral-600">Send us a message and we'll respond as soon as possible</small>
        </div>
        <div className="registration-form sm:py-8 py-4 sm:px-4 px-2 sm:w-1/2 w-full mx-auto bg-white rounded shadow text-left">
            <form>
            <div class="mb-4">
                        <label class="block text-grey-darker text-sm font-bold mb-2" for="name">Name</label>
                        <input class="appearance-none border rounded w-full py-2 px-3 text-grey-darker border-slate-300" id="name" type="text" placeholder="Your name" />
                    </div>
                <div class="mb-4">
                    <label class="block text-grey-darker text-sm font-bold mb-2" for="email">Email</label>
                    <input class="appearance-none border rounded w-full py-2 px-3 text-grey-darker border-slate-300" id="email" type="email" placeholder="Your email" />
                </div>
                <div class="mb-4">
                    <label class="block text-grey-darker text-sm font-bold mb-2" for="email">Email</label>
                    <input class="appearance-none border rounded w-full py-2 px-3 text-grey-darker border-slate-300" id="email" type="tel" placeholder="Phone No." />
                </div>
                <div class="mb-4">
                    <label class="block text-grey-darker text-sm font-bold mb-2" for="message">Message</label>
                    <textarea class="appearance-none border rounded w-full py-2 px-3 text-grey-darker border-slate-300" id="message" type="text" placeholder="Message" />
                </div>
                <div className="flex items-center gap-4">
                    <div className="w-1/2">
                        <button type="submit" className="bg-blue-700 text-white px-4 py-3 rounded-lg">Contact us</button>
                    </div>
                </div>
            </form>
        </div>
        </div>
        </>
    )
}
export default Contact;