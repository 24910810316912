import React from "react";
import aboutImg from '../assets/images/aboutImg.jpg'
import customerService from '../assets/images/customer-feedback.png' 

const About =()=>{
    return (
        <>
        <div className="about-sec">
            <div className="about-banner sm:px-20 px-4 sm:py-20 py-10 flex justify-center items-center">
                <h2 className="font-bold sm:text-5xl text-4xl text-center text-white absolute bottom-0 sm:pb-14 pb-4 z-10">About Us</h2>
            </div>
            <div className="sm:px-20 px-4 sm:py-10 py-4">
            <div className="flex justify-between sm:flex-row flex-col gap-8">
                <div className="sm:w-1/2 text-left">
                    <h1 className="font-bold text-3xl text-orange-600 pb-4">Who We Are</h1>
                    <p>It has been 4 years since flightiers.com decided to let the world become a part of ourselves. In our journey of becoming modest. We ourselves traveled thousands of miles to gain expertise in flights, hotels, sightseeing, ticket booking, flight reservations and destinations. Instead of listening to stories, we explored every corner of the globe personally to understand what it takes to offer an authentic, personalized and hassle-free experience in the travel world. We keep growing with our sacred bond with you And, today we are here and known for what you have always loved us for - the Flightiers experience.</p>
                </div>
                <div className="sm:w-1/2 w-full aboutImg">
                    <img src={aboutImg} alt="aboutImg" className="rounded-lg"/>
                </div>
            </div>
            <section className="sm:pt-20 pt-10">
            <h2 className="font-bold text-3xl text-orange-600 sm:pb-8 pb-4 text-center">Why Choose Us</h2>
            <div className="flex justify-between sm:flex-row flex-col gap-6">
                <div className="w-full border-2 border-gray-200 rounded-lg sm:py-4 py-2 sm:px-4 px-2 hover:border-blue-500 delay-100 transition-all bg-blue-700">
                    <div className="icon-box text-left">
                        <div className="imgIcon flex justify-center items-center">
                            <img src={customerService} alt="customerService" />
                        </div>
                        <h5 className="pt-4 font-bold text-white text-2xl pb-2">Hassle-free Bookings</h5>
                        <p className="text-gray-100">With our flight booking and hotels reservation portal bookings are super easy. Find the most reasonable flight tickets and hotel reservation, all at one place. Our cheap flight tickets and hotel reservation combo fares will boggle your mind. Explore our round trip bookings with pocket-friendly fares.
                        </p>
                    </div>
                   </div>
                   <div className="w-full border-2 border-gray-200 rounded-lg sm:py-4 py-2 sm:px-4 px-2 hover:border-blue-500 delay-100 transition-all">
                   <div className="icon-box text-left">
                        <img src={customerService} alt="customerService" />
                        <h5 className="pt-4 font-bold text-blue-900 text-2xl pb-2">Custom-made Itineraries</h5>
                        <p className="text-gray-700">We make you an customized itinerary, just the way you want. Our tailor-made itineraries are curated to accommodate all your requirements, and to give you a wholesome experience in your budget.
                        </p>
                    </div>
                   </div>
                   <div className="w-full border-2 border-gray-200 rounded-lg sm:py-4 py-2 sm:px-4 px-2 hover:border-blue-500 delay-100 transition-all">
                   <div className="icon-box text-left">
                        <img src={customerService} alt="customerService" />
                        <h5 className="pt-4 font-bold text-blue-900 text-2xl pb-2">Unbelievable Prices & Deals</h5>
                        <p className="text-gray-700">We know how much you love discounts and deals! And with Flightiers, discounts and deals are a must-have. Our jaw dropping prices and deals will make you want to turn into a globetrotter right away. If you are looking for a best budget trip, Flightiers is your go-to place.
                        </p>
                    </div>
                   </div>
            </div>
        </section>
            </div>
        </div>
        </>
    )
}

export default About;